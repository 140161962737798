<template>
	<div>
		<!--提示-->
		<el-alert :title="`任务状态：${taskStatusName}`" type="warning" :closable="false"></el-alert>
		<el-card>
      <h3>账户信息</h3>
      <WarmPrompt></WarmPrompt>
      <el-table :data="companyVerify.data" border class="bankAccountList mt20">
        <el-table-column prop="bankName" label="账户名称" min-width="190px"></el-table-column>
        <el-table-column prop="bankNumber" label="账号" min-width="180px"></el-table-column>
        <el-table-column prop="openBank" label="开户行" min-width="80px"></el-table-column>
        <el-table-column prop="provinceId" label="开户省" min-width="80px"></el-table-column>
        <el-table-column prop="cityId" label="开户市" min-width="80px"></el-table-column>
        <el-table-column prop="clearingNumber" label="联行号" min-width="120px"></el-table-column>
        <el-table-column prop="bankBranch" label="所属支行名称" min-width="150px"></el-table-column>
        <el-table-column prop="cfcaBankCodeName" label="开户行（票交所）" min-width="150px"></el-table-column>
        <el-table-column prop="isCloseAccount" label="是否结算账户" min-width="120px">
          <template v-slot="scope">
            <span v-if="scope.row.isCloseAccount == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="accountType" label="账户类别" min-width="120px"></el-table-column>
        <el-table-column prop="accountStartDay" label="账户生效日" min-width="120px"></el-table-column>
        <el-table-column prop="accountEndDay" label="账户到期日" min-width="120px"></el-table-column>
        <el-table-column prop="smallValidation" label="小额验证状态" min-width="120px">
          <template v-slot="scope">
            <span v-if="scope.row.smallValidation == 1">通过</span>
            <span v-else-if="scope.row.smallValidation == 2">打款申请通过</span>
            <span v-else>未通过</span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
	</div>
</template>

<script>
  /*温馨提示*/
  import WarmPrompt from '@/views/Ticket/childrenCpn/WarmPrompt/Index'
  export default {
    name: "CompanyVerifyDetail",
	  props: ['companyVerify'],
    components: {
      WarmPrompt
    },
	  computed: {
      taskStatusName(){
        if (this.companyVerify.taskStatusName){
          return this.companyVerify.taskStatusName
        }else {
          return  ''
        }
      }
	  }
  }
</script>

<style lang="less" scoped>
	.el-alert{
		padding: 16px !important;
		margin-top: 20px;
		/deep/ .el-alert__title{
			font-size: 15px;
		}
	}
</style>