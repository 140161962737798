<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>企业准入管理</el-breadcrumb-item>
			<el-breadcrumb-item>企业身份验证</el-breadcrumb-item>
		</el-breadcrumb>
		<!--编辑页-->
		<el-card v-if="isClickSubmit === 0">
			<!--步骤条-->
			<el-steps :active="activeStep"
			          process-status="wait"
			          finish-status="finish"
			          align-center>
				<el-step title="银行卡小额打款验证"></el-step>
				<el-step title="完成"></el-step>
			</el-steps>
			<!--银行卡小额打款验证-->
			<CompanyVerifyOne v-if="activeStep == 1" @handleVerifySave="handleVerifySave"/>
			<!--完成-->
			<Status v-else>
				<template v-slot:statusDescribe>
					<h3>提交成功</h3>
				</template>
				<template v-slot:statusBtn>
					<el-button type="primary" size="medium" @click="submitVerify">完成</el-button>
				</template>
			</Status>
		</el-card>
		<!--详情页-->
		<CompanyVerifyDetail :companyVerify="companyVerify" v-else/>
	</div>
</template>

<script>
	//银行卡小额打款验证
	import CompanyVerifyOne from "./childCpn/CompanyVerifyOne";
	//完成
  import Status from "@/views/Ticket/childrenCpn/Status";
  // 详情
	import CompanyVerifyDetail from "./childCpn/CompanyVerifyDetail";
  export default {
    name: "companyVerify",
	  components: {
      Status,
      CompanyVerifyOne,
      CompanyVerifyDetail
	  },
    mounted(){
      // 获取企业准入数据
      this.getCompanyAccess()
    },
	  data(){
      return {
        activeStep: 1, // 步骤条当前激活步骤
	      companyVerify: {}, // 企业身份验证的数据
        isClickSubmit: 1 // 判断是否显示详情
      }
	  },
	  methods: {
      // 获取企业身份验证数据
      async getCompanyAccess(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/front-bank-ext/getBankInfoByCompanyId');
        if (res.code !== 200) return this.$message.error(res.msg)
        this.companyVerify = res
	      this.isClickSubmit = res.isClickSubmit
      },
			// 获取验证第一步保存过来的数据
      handleVerifySave(activeStep){
        this.activeStep = activeStep
      },
      // 完成
      submitVerify(){
        //显示详情页
        this.isClickSubmit = 1;
        // 企业身份验证回到第一步
	      this.activeStep = 1
      }
	  }
  }
</script>

<style lang="less" scoped>
	.el-alert{
		padding: 16px !important;
		margin-top: 10px;
		/deep/ .el-alert__title{
			font-size: 15px;
		}
	}

</style>