<template>
	<el-button :type="type"
	           :disabled="!canClickRun"
	           @click="clickBtn">
		{{canClickRun ? text : `重新发送(${delayTime}s)`}}
	</el-button>
</template>

<script>
  export default {
    name: "DelayButton",
	  props: {
      text: {
        type: String,
	      default: '发送验证码'
      },
		  type: {
        type: String,
			  default: 'primary'
		  }
	  },
	  data(){
      return {
        canClickRun: true, // 控制显示的文本和按钮是否禁用
	      delayTime: 60 // 倒计时初始数
      }
	  },
	  methods: {
      async clickBtn(){
        this.canClickRun = false;
        let timer = setInterval(() => {
          this.delayTime -= 1;
          if (this.delayTime <= 0){
            clearInterval(timer);
            this.canClickRun = true
            this.delayTime = 60
          }
        },1000)
	      const {data: res} = await this.$auth.get("cpiaoju-ticket/front-company-register/companyApplySendSMS")
        if(res.code !== 200) return this.$message.error(res.msg);
        this.$message.success(res.msg);
      }
	  }
  }
</script>

<style scoped>
</style>