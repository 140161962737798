<template>
	<div>
		<!--账户信息-->
		<h3 class="mt20">账户信息</h3>
    <WarmPrompt></WarmPrompt>
		<el-table :data="bankAccountList" border class="bankAccountList">
			<el-table-column prop="bankName" label="账户名称" min-width="190px"></el-table-column>
			<el-table-column prop="bankNumber" label="账号" min-width="180px"></el-table-column>
			<el-table-column prop="openBank" label="开户行" min-width="80px"></el-table-column>
			<el-table-column prop="provinceId" label="开户省" min-width="80px"></el-table-column>
			<el-table-column prop="cityId" label="开户市" min-width="80px"></el-table-column>
			<el-table-column prop="clearingNumber" label="联行号" min-width="120px"></el-table-column>
			<el-table-column prop="bankBranch" label="所属支行名称" min-width="150px"></el-table-column>
			<el-table-column prop="cfcaBankCodeName" label="开户行（票交所）" min-width="150px">
				<template v-slot="scope">
					<el-autocomplete v-model="scope.row.cfcaBankCodeName"
					                 :disabled="scope.row.isClickVerify === 1"
					                 :fetch-suggestions="querySearchAsync"
					                 value-key="bankName"
					                 placeholder="请输入内容"
					                 @select="handleSelect(scope.row)"></el-autocomplete>
				</template>
			</el-table-column>
			<el-table-column prop="isCloseAccount" label="是否结算账户" min-width="120px">
				<template v-slot="scope">
					<span v-if="scope.row.isCloseAccount == 1">是</span>
					<span v-else>否</span>
				</template>
			</el-table-column>
			<el-table-column prop="accountType" label="账户类别" min-width="120px"></el-table-column>
			<el-table-column prop="accountStartDay" label="账户生效日" min-width="120px"></el-table-column>
			<el-table-column prop="accountEndDay" label="账户到期日" min-width="120px"></el-table-column>
			<el-table-column prop="smallValidation" label="小额验证状态" min-width="120px">
				<template v-slot="scope">
					<span v-if="scope.row.smallValidation == 1">通过</span>
					<span v-else-if="scope.row.smallValidation == 2">打款申请通过</span>
					<span v-else>未通过</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="180px">
				<template v-slot="scope">
					<el-button type="primary"
					           size="mini"
					           :disabled="scope.row.isClickVerify === 1"
					           @click="handleSmallAmount(scope.row)">
						小额验证
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!--确定提交按钮-->
		<el-button type="primary"
		           class="submit"
		           v-show="isClickSubmit === 0"
		           @click="messageDialogVisible = true">
			确认提交
		</el-button>
		<!--小额验证弹框-->
		<el-dialog
						title="小额打款验证"
						:visible.sync="verifyDialogVisible"
						:show-close="false"
						width="50%"
						@close="handleVerifyClose">
			<el-alert title="业务说明：验证金额3次错误后，失效。用户需要重新验证"
								type="warning"
								:closable="false">
			</el-alert>
			<p style="margin-top: 10px; padding-left: 55px;">我们已向您尾号（{{bankNumber.substring(bankNumber.length - 4, bankNumber.length)}}）的银行账号转入验证小款，请尽快查收验证！</p>
			<el-form :model="verifyForm"
			         :rules="verifyFormRules"
			         ref="verifyFormRef"
			         label-width="180px"
			         class="mt20">
				<el-form-item label="验证金额（元）：" prop="verifyAmount">
					<el-input v-model="verifyForm.verifyAmount" class="verify-code"></el-input>
				</el-form-item>
			</el-form>
			<div class="verify-result">{{verifyMsg}}</div>
			<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="handleSmallAmountAgain">重新验证</el-button>
					<el-button type="primary" @click="handleVerifySubmit">确 定</el-button>
			    <el-button @click="verifyDialogVisible = false">取 消</el-button>
			  </span>
		</el-dialog>
		<!--短信验证-->
		<el-dialog
						title="短信验证"
						:visible.sync="messageDialogVisible"
						:close-on-click-modal="false"
						width="50%"
						@close="messageDialogClosed">
			<el-form :model="messageForm"
			         :rules="messageFormRules"
			         ref="messageFormRef"
			         class="message-form">
				<el-form-item label="操作员手机号" prop="operatorMobile">
					{{phoneNumber}}
				</el-form-item>
				<el-form-item label="短信验证码" prop="verifyCode">
					<el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
					<VerifyDelayButton/>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleMessageSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
  import VerifyDelayButton from "@/views/Ticket/childrenCpn/VerifyDelayButton";
  /*温馨提示*/
  import WarmPrompt from '@/views/Ticket/childrenCpn/WarmPrompt/Index'
  export default {
    name: "CompanyVerifyOne",
	  components: {
      VerifyDelayButton,
      WarmPrompt
	  },
    mounted(){
      // 获取企业准入数据
      this.getCompanyAccess()
    },
	  data(){
      return {
        bankAccountList: [], // 列表数据
        cfcaBankList: [], //cfca银行
        bankExtId: 1, // 银行账户EXT表ID - 用于验证点击小额按钮是否弹框
        cfcaBankId: 0, //后加的
	      bankNumber: '', // 账号 - 用于显示在小额验证弹框
        isClickSubmit: false, // 确认提交 按钮是否 显示（1=不显示，0=显示）
        phoneNumber: '', //点击“确认提交”按钮的 操作员手机号
        verifyDialogVisible: false, // 控制小额验证弹框的显示与隐藏
	      verifyMsg: '', //小额验证失败文字提示
        verifyForm: {
          verifyAmount: '', // 小额验证金额
        },
        verifyFormRules: {
          verifyAmount: [
            { required: true, message: '请输入验证金额', trigger: 'blur' }
          ]
        },
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
      }
	  },
    methods: {
      // 获取企业身份验证数据
      async getCompanyAccess(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/front-bank-ext/getBankInfoByCompanyId');
        if (res.code !== 200) return this.$message.error(res.msg)
        this.bankAccountList = res.data;
	      this.isClickSubmit = res.isClickSubmit;
	      this.phoneNumber = res.phoneNumber
      },
	    // 监听小额验证按钮
      async handleSmallAmount(row){
        this.bankExtId = row.bankExtId;
        this.cfcaBankId = row.cfcaBankId
        this.bankNumber = row.bankNumber
	      this.verifyMsg = ''; // 清空小额失败文字提示
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/front-bank-ext/paymentApply",{params: {bankExtId: this.bankExtId, cfcaBankId: row.cfcaBankId}});
        if (res.code === 200){
          this.verifyDialogVisible = true
        }else {
          this.$message.error(res.msg);
          // 重新获取数据
	        this.getCompanyAccess()
        }
      },
      // 小额重新验证
      async handleSmallAmountAgain(){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/front-bank-ext/paymentApply",{params: {bankExtId: this.bankExtId, cfcaBankId: this.cfcaBankId}});
        if (res.code === 200){
          this.verifyDialogVisible = true
	        this.$message.success(res.data)
        }else {
          this.$message.error(res.msg);
          // 重新获取数据
          this.getCompanyAccess()
        }
      },
      // 小额弹框关闭
      handleVerifyClose(){
        this.$refs.verifyFormRef.resetFields()
      },
      // 小额验证确定
      handleVerifySubmit(){
        this.$refs.verifyFormRef.validate(async valid => {
          if(!valid) return;
          let params = {
            bankExtId: this.bankExtId,
            verifyAmount: this.verifyForm.verifyAmount
          }
	        const {data: res} = await this.$auth.get("/cpiaoju-ticket/front-bank-ext/smallPaymentVerify",{params})
          if(res.code !== 200) {
            this.verifyMsg = res.msg
          }else {
            this.verifyDialogVisible = false;
            // 重新获数据
	          this.getCompanyAccess()
          }
        })
      },
      // 短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
	    /*短信验证提交*/
	    handleMessageSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          if (!valid) return
          const {data: res} = await this.$auth.get("/cpiaoju-ticket/front-company-register/companyApplyAuth",{params: {smsCode: this.messageForm.verifyCode}})
          if(res.code !== 200) return this.$message.error(res.msg);
          this.messageDialogVisible = false
          this.$emit('handleVerifySave', 2)
        })
	      
      },
      // 获取cfca银行编码和名称
      async getCfcaBank(queryString = ''){
        const {data: res} = await this.$auth.get("/cpiaoju-ticket/cfca-bank-code/getCfcaBank", {params: {cfcaBankName: queryString}})
        if(res.code !== 200) return this.$message.error(res.msg);
        this.cfcaBankList = res.data.records;
      },
      // cfca模糊搜索
      querySearchAsync(queryString, cb){
        // 获取cfca银行编码和名称
        this.getCfcaBank(queryString)
        let result = queryString ? this.cfcaBankList.filter(this.createStateFilter(queryString)) : this.cfcaBankList
        cb(result)
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.bankName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      // 监听cfca模糊搜索选择
      async handleSelect(row){
        let cfcaBankCodeName = row.cfcaBankCodeName
        let cfcaItem = this.cfcaBankList.filter(item => item.bankName == cfcaBankCodeName)
        row.cfcaBankId = cfcaItem[0].id
      },
    }
  }
</script>

<style lang="less" scoped>
	/deep/ .el-dialog__body{
		padding: 10px 20px !important;
	}
	/*确认提交按钮*/
	.submit{
		display: block;
		width: 150px;
		margin: 30px auto 0;
	}
	
	/*账户信息*/
	.bankAccountList{
		margin-top: 20px;
		.el-input{
			width: 150px !important;
		}
	}
	.el-dialog{
		.verify-code{
			width: 220px !important;
			margin-right: 10px;
		}
		.verify-explain, .verify-result{
			color: #C70009;
		}
		.verify-explain{
			margin-top: 20px;
		}
		.verify-result{
			margin-left: 35px;
		}
	}
</style>